// src/slices/shipmentSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Shipment, ShipmentState } from './types';

const initialState: ShipmentState = {
  shipmentInfo: [],
  loadRoute: [],
  arrivalRoute: []
};

export const shipmentSlice = createSlice({
  name: 'shipment',
  initialState,
  reducers: {
    reset: () => initialState,
    setFtlInfo: (state, action: PayloadAction<Shipment[]>) => {
      state.shipmentInfo = action.payload;
    },
    returnFtlInfo: (state, action: PayloadAction<Shipment[]>) => {
      state.shipmentInfo = action.payload;
    },
    // eslint-disable-next-line
    setLoadRoute: (state, action: PayloadAction<any[]>) => {
      state.loadRoute = action.payload;
    },
    // eslint-disable-next-line
    setArrivalRoute: (state, action: PayloadAction<any[]>) => {
      state.arrivalRoute = action.payload;
    }
  }
});

export const { setFtlInfo, setLoadRoute, setArrivalRoute } = shipmentSlice.actions;

export default shipmentSlice.reducer;
