// MaterialUI
import { Avatar, Box, Grid, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';

import { useGetUserProfileQuery } from '@/store/api/auth/api';

import Profile from './profile';

const Navbar = (): JSX.Element => {
  const [anchorEl] = React.useState<null | HTMLElement>(null);
  const [showProfile, setShowProfile] = useState(false);
  const open = Boolean(anchorEl);
  const { data: userProfile } = useGetUserProfileQuery();

  const changeShowProfileStatus = (newStatus: boolean): void => {
    setShowProfile(newStatus);
  };

  return (
    <Grid xs={12} item container justifyContent="end" paddingRight="33px">
      <Stack direction="row" alignItems="center">
        <Stack marginBottom={'0'} marginRight="19px">
          <Typography lineHeight={0.4} color={'#002E6D'} variant="body2">
            {userProfile?.name}
          </Typography>
          <Typography color={'#002E6D'} variant="caption">
            {userProfile?.email}
          </Typography>
        </Stack>
        <React.Fragment>
          <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
            <Tooltip title="Hesap Ayarları">
              <IconButton
                onClick={() => {
                  setShowProfile(true);
                }}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
              >
                <Avatar sx={{ width: '36px', height: '36px', backgroundColor: '#002E6D' }}>F</Avatar>
              </IconButton>
            </Tooltip>
          </Box>
        </React.Fragment>
      </Stack>
      <Profile changeDrawerStatus={changeShowProfileStatus} drawerStatus={showProfile} />
      {/* Navbar companent is here!! */}
    </Grid>
  );
};

export default Navbar;
