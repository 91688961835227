// for Redirect
import { trTR } from '@mui/material/locale';
import { createTheme, ThemeOptions } from '@mui/material/styles';

import { colorPalette } from './colorPalette';
import { MuiMenu } from './menu';
import { MuiMenuItem } from './menuItem';
import { typographyOptions } from './typography';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    white: true;
  }
}

export const dashboardTheme = createTheme(
  {
    spacing: 5,
    palette: { ...colorPalette },
    typography: { ...typographyOptions },
    components: {
      MuiRadio: {
        styleOverrides: {
          root: {
            color: 'black', // Seçilmemiş radio button rengi
            '&.Mui-checked': {
              color: 'black' // Seçili radio button rengi
            }
          }
        }
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            color: '#002E6D' // Yazı rengini buradan değiştirebilirsiniz
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            '& .MuiInputLabel-root': {
              color: '#002E6D'
            },
            '& .MuiInputBase-input': {
              color: '#002E6D',
              padding: '8px 30px 8px 17px',
              borderRadius: '5px',
              lineHeight: '1.9'
            },
            '& .MuiSelect-select': {
              '&:focus': {
                backgroundColor: '#ffffff',
                borderRadius: '5px'
              }
            },
            '& .MuiSelect-select-MuiInputBase-input': {
              paddingRight: '50px'
            },
            '& .MuiList-root': {
              borderRadius: '5px'
            },
            '& .DashboardSelectIconStyle': {
              position: 'absolute',
              right: '14.5px',
              top: 'calc(50% - 0.30em)',
              userSelect: 'none',
              height: '12px',
              paddingLeft: '30px',
              width: '20px',
              display: 'inline-block',
              flexShrink: 0,
              pointerEvents: 'none'
            },
            '&.Mui-disabled': {
              opacity: 0.4
            },
            '& .MuiSvgIcon-root': {
              fontSize: '30px',
              color: '#002E6D'
            }
          }
        }
      },
      MuiButton: {
        // Buton özelliğini ekleyin
        styleOverrides: {
          root: {
            width: '100%', // Genişlik 100%
            textTransform: 'none', // Büyük/küçük harf dönüşümü yok
            borderRadius: '9px', // 9 piksel yuvarlatılmış kenarlar
            fontSize: '14px'
          },
          text: {
            // Styles specific to text variant
            color: '#002E6D', // Text button text color
            background: 'transparent', // No background for text button
            border: 'none', // No border for text button
            padding: '8px 16px', // Padding for text button
            '&:hover': {
              backgroundColor: 'rgba(0, 46, 109, 0.1)' // Light background on hover
            }
          },
          outlined: {
            backgroundColor: '#212427', // Arka plan rengi
            color: 'white', // Yazı rengi
            fontWeight: 'bold',
            '&:hover': {
              backgroundColor: '#212427' // Fare üzerine gelindiğinde arka plan rengi
            },
            '&:disabled': {
              backgroundColor: '#212427', // Arka plan rengi
              color: 'white', // Yazı rengi
              opacity: 0.5
            }
          }
        },
        variants: [
          {
            props: { variant: 'white' },
            style: {
              color: '#002E6D',
              backgroundColor: '#ffffff',
              border: 0,
              borderRadius: '5px !important',
              padding: '6px 20px !important',
              boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
              '&:hover': {
                backgroundColor: '#ffffff'
              }
            }
          }
        ]
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            '& .MuiInputBase-input': {
              color: '#212427' // TextField içindeki yazı rengini kırmızı yap
            },
            '&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
              borderColor: '#002E6D',
              borderWidth: '2px'
            },
            '&.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
              borderColor: '#002E6D'
            },
            '& .MuiOutlinedInput-input': {
              padding: '12px 14px',
              height: '1.2rem'
            }
          }
        }
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
            width: 42,
            height: 27,
            padding: 0,
            margin: 8,
            '& .MuiSwitch-switchBase': {
              padding: 1,
              '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#FFC400',
                '& + .MuiSwitch-track': {
                  backgroundColor: '#FFC400',
                  opacity: 1
                },
                '&:hover': {
                  backgroundColor: 'rgba(255, 196, 0, 0.08)'
                }
              },
              '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5
              }
            },
            '& .MuiSwitch-thumb': {
              boxSizing: 'border-box',
              width: 25,
              height: 25,
              backgroundColor: '#000000' // Yuvarlağı siyah yap
            },
            '& .MuiSwitch-track': {
              borderRadius: 27 / 2,
              backgroundColor: '#D9D9D980',
              opacity: 1,
              transition: 'background-color 0.3s'
            }
          }
        }
      },

      ...MuiMenu,
      ...MuiMenuItem
    }
  },
  trTR as ThemeOptions
);
