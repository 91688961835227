// App.js
import { useSelector } from 'react-redux';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';

import Loader from '@/components/Loaders/Loader';
import LoginLayout from '@/layouts/auth/index';
import DashbordLayout from '@/layouts/dashboard/layouts-dashbord';
import { AuthStatus } from '@/page/Login/reducer/auth-slice';
import { ROUTES } from '@/router/router';
import { RootState } from '@/store/store';

const publicRouter = createBrowserRouter([
  {
    path: '/auth',
    element: <LoginLayout />,
    errorElement: <Navigate to={ROUTES.ERROR.PATH} />,
    children: [
      {
        path: ROUTES.AUTHENTICATION.LOGIN.PATH,
        lazy: () => import('@/page/Login')
      }
    ]
  },
  {
    path: ROUTES.ERROR.PATH,
    lazy: () => import('@/page/Error')
  },
  {
    path: '*',
    errorElement: <Navigate to={ROUTES.ERROR.PATH} />,
    element: <Navigate to="/auth/login" />
  }
]);

const privateRouter = createBrowserRouter([
  {
    path: '/',
    element: <DashbordLayout />,
    errorElement: <Navigate to={ROUTES.ERROR.PATH} />,
    children: [
      {
        path: ROUTES.INDEX.PATH,
        lazy: () => import('@/page/Dashboard')
      },
      {
        path: ROUTES.DASHBOARD.COMPANY_MANAGEMENT.COMPANY_ADD.PATH,
        lazy: () => import('@/page/CompanyManagement/ApprovedCompanies/CompanyAdd/add')
      },
      {
        path: `${ROUTES.DASHBOARD.COMPANY_MANAGEMENT.APPROVED_COMPANIES_DETAIL.PATH}/:companyid`,
        lazy: () => import('@/page/CompanyManagement/ApprovedCompanies/approved-companies-detail')
      },
      {
        path: `${ROUTES.DASHBOARD.COMPANY_MANAGEMENT.APPROVED_COMPANIES_EDIT.PATH}/:companyid`,
        lazy: () => import('@/page/CompanyManagement/ApprovedCompanies/CompanyEdit/edit')
      },
      {
        path: ROUTES.DASHBOARD.COMPANY_MANAGEMENT.APPROVED_COMPANIES.PATH,
        lazy: () => import('@/page/CompanyManagement/ApprovedCompanies')
      },
      {
        path: ROUTES.DASHBOARD.COMPANY_MANAGEMENT.PENDING_COMPANIES.PATH,
        lazy: () => import('@/page/CompanyManagement/PendingCompanies')
      },
      {
        path: `${ROUTES.DASHBOARD.COMPANY_MANAGEMENT.PENDING_COMPANIES_DETAIL.PATH}/:companyid`,
        lazy: () => import('@/page/CompanyManagement/PendingCompanies/CompanyApprove/approve')
      },
      {
        path: ROUTES.DASHBOARD.USER_MANAGEMENT.PATH,
        lazy: () => import('@/page/UserManagement')
      },
      {
        path: ROUTES.DASHBOARD.DRIVER_MANAGEMENT.APPROVED_DRIVERS.PATH,
        lazy: () => import('@/page/DriverManagement/ApprovedDrivers')
      },
      {
        path: ROUTES.DASHBOARD.DRIVER_MANAGEMENT.PENDING_DRIVERS.PATH,
        lazy: () => import('@/page/DriverManagement/PendingDrivers')
      },
      {
        path: `${ROUTES.DASHBOARD.DRIVER_MANAGEMENT.APPROVED_DRIVERS_DETAIL.PATH}/:driverid`,
        lazy: () => import('@/page/DriverManagement/ApprovedDrivers/approved-drivers-detail')
      },
      {
        path: `${ROUTES.DASHBOARD.DRIVER_MANAGEMENT.APPROVED_DRIVERS_EDIT.PATH}/:driverid`,
        lazy: () => import('@/page/DriverManagement/ApprovedDrivers/approved-drivers-edit')
      },
      {
        path: `${ROUTES.DASHBOARD.DRIVER_MANAGEMENT.PENDING_DRIVERS_DETAIL.PATH}/:driverid`,
        lazy: () => import('@/page/DriverManagement/PendingDrivers/pending-drivers-detail')
      },
      {
        path: ROUTES.DASHBOARD.PROJECT_MANAGEMENT.APPROVED_PROJECTS_ADD.PATH,
        lazy: () => import('@/page/ProjectManagement/ApprovedProjects/project-add')
      },
      {
        path: `${ROUTES.DASHBOARD.PROJECT_MANAGEMENT.APPROVED_PROJECTS_EDIT.PATH}/:projectid`,
        lazy: () => import('@/page/ProjectManagement/ApprovedProjects/project-edit')
      },
      {
        path: ROUTES.DASHBOARD.PROJECT_MANAGEMENT.APPROVED_PROJECTS.PATH,
        lazy: () => import('@/page/ProjectManagement/ApprovedProjects/index')
      },
      {
        path: `${ROUTES.DASHBOARD.PROJECT_MANAGEMENT.APPROVED_PROJECTS_DETAIL.PATH}/:projectid`,
        lazy: () => import('@/page/ProjectManagement/ApprovedProjects/approved-project-detail')
      },
      {
        path: ROUTES.DASHBOARD.PROJECT_MANAGEMENT.PENDING_PROJECTS.PATH,
        lazy: () => import('@/page/ProjectManagement/PendingProjects/index')
      },
      {
        path: `${ROUTES.DASHBOARD.PROJECT_MANAGEMENT.PENDING_PROJECTS_DETAIL.PATH}/:projectid`,
        lazy: () => import('@/page/ProjectManagement/PendingProjects/pending-project-detail')
      },
      {
        path: ROUTES.DASHBOARD.VEHICLE_MANAGEMENT.APPROVED_VEHICLES.PATH,
        lazy: () => import('@/page/VehicleManagement/ApprovedVehicles')
      },
      {
        path: `${ROUTES.DASHBOARD.VEHICLE_MANAGEMENT.APPROVED_VEHICLES_DETAIL.PATH}/:vehicleid`,
        lazy: () => import('@/page/VehicleManagement/ApprovedVehicles/approved-vehicles-detail')
      },
      {
        path: `${ROUTES.DASHBOARD.VEHICLE_MANAGEMENT.APPROVED_VEHICLES_EDIT.PATH}/:vehicleid`,
        lazy: () => import('@/page/VehicleManagement/ApprovedVehicles/approved-vehicles-edit')
      },
      {
        path: ROUTES.DASHBOARD.VEHICLE_MANAGEMENT.PENDING_VEHICLES.PATH,
        lazy: () => import('@/page/VehicleManagement/PendingVehicles')
      },
      {
        path: `${ROUTES.DASHBOARD.VEHICLE_MANAGEMENT.PENDING_VEHICLES_DETAIL.PATH}/:vehicleid`,
        lazy: () => import('@/page/VehicleManagement/PendingVehicles/pending-vehicles-detail')
      },
      {
        path: ROUTES.DASHBOARD.SPOT_MARKET.PATH,
        lazy: () => import('@/page/SpotMarket')
      },
      {
        path: ROUTES.DASHBOARD.SHIPMENT_MANAGEMENT.PATH,
        lazy: () => import('@/page/ShipmentManagement')
      },
      {
        path: ROUTES.DASHBOARD.SHIPMENT_MANAGEMENT.SHIPMENT_EDIT.PATH,
        lazy: () => import('@/page/ShipmentManagement/edit/index')
      }
    ]
  },
  {
    path: '*',
    element: <Navigate to="/" />
  },
  {
    path: ROUTES.ERROR.PATH,
    element: <DashbordLayout />,
    children: [
      {
        path: ROUTES.ERROR.PATH,
        lazy: () => import('@/page/Error')
      }
    ]
  },
  {
    path: ROUTES.SHIPMENT_DETAIL.PATH,
    errorElement: <Navigate to={ROUTES.ERROR.PATH} />,
    lazy: () => import('@/page/ShipmentDetail/Index')
  }
]);

function App() {
  const AUTH_STATUS = useSelector((state: RootState) => state.auth.AUTH_STATUS) as AuthStatus;

  if (AUTH_STATUS === AuthStatus.LOADING) {
    return <Loader />;
  }

  return <RouterProvider router={AUTH_STATUS === AuthStatus.LOGGED_IN ? privateRouter : publicRouter} fallbackElement={<Loader />} />;
}

export default App;
